import React, { ReactElement, useEffect } from 'react';
import { Layout } from '../components/Layout/Layout';
import { Intro } from '../modules/Intro/Intro';

const  WaysToEmailLikeABossPage = (): ReactElement => (
    useEffect(()=>{
        setTimeout(function(){
            window.location.replace('https://dub.sh/12_Ways_To_Email_Like_A_Boss')
        }, 100)
        

        }, []),

        <Layout>
        <Intro
      title={
        <> </>
      }
      subTitle={<>12 Ways To Email Like A Boss</>}
      
    />
  </Layout>
);

export default  WaysToEmailLikeABossPage;
